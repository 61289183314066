<template>
  <div>
    <div class="program-detail-hero">
      <div class="program-detail-hero__left">
         <figure>
            <figcaption>पहिला</figcaption>
            <img :src="programDetails.imageid1before" alt="फोटो पहिला" />
          </figure>
      </div>
      <div class="program-detail-hero__right">
        <figure>
            <figcaption>अहिले</figcaption>
            <img :src="programDetails.imageid3after" alt="फोटो अहिले" />
          </figure>
      </div>
    </div>
    <div class="program-detail" v-if="programDetails">
      <router-link :to="{ name: 'achievements' }" class="detail-back-btn" @click="$router.back()">
        <unicon name="arrow-left"></unicon>सूची मा फर्कनुहोस्
      </router-link>
      <Achievement
        :achievement="programDetails"
        :showThumb="false"
        :showActionLink="false"
      />
      <section class="program-photos">
        <h3>फोटो</h3>
        <div class="program-section-body">
          <figure class="program-detail__photo-before">
            <img :src="programDetails.imageid1before" alt="फोटो पहिला" />
            <figcaption>पहिला</figcaption>
          </figure>
          <figure class="program-detail__photo-after">
            <img :src="programDetails.imageid3after" alt="फोटो अहिले" />
            <figcaption>अहिले</figcaption>
          </figure>
          <figure class="program-detail__photo-before">
            <img :src="programDetails.imageid2before" alt="फोटो पहिला" />
            <figcaption>पहिला</figcaption>
          </figure>
          <figure class="program-detail__photo-after">
            <img :src="programDetails.imageid4after" alt="फोटो अहिले" />
            <figcaption>अहिले</figcaption>
          </figure>
        </div>
      </section>
      <section class="program-description">
        <h3>विवरण</h3>
        <div class="program-section-body">
          {{ programDetails.description }}
        </div>
      </section>

      <section class="program-challenges">
        <h3>चुनौती</h3>
        <div class="program-section-body">
          {{ programDetails.challenges }}
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      programDetails: {}
    };
  },
  computed: {
    achievementProgram() {
      return this.$store.getters.achievements;
    }
  },
  components: {
    Achievement: () => import("./achievement.vue")
  },
  watch: {
    achievementProgram: {
      handler: "getProgramDetails",
      immediate: true
    }
  },
  methods: {
    getProgramDetails() {
      let id = this.$route.params.achievementId;
      this.programDetails = this.$store.state.achievements.find((ele) => {
        return ele["s.no."] === id;
      });
      // console.log(this.$store.state.achievements)
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
